
import { Options } from "vue-class-component";
import ComponentMixins from "../ComponentMixins";

@Options({
    name: "flowable-common-form-components-date-time",
    props: {
        format: {
            type: String,
            default: "yyyy-MM-dd HH:mm",
        },
        placeholder: {
            type: String,
            default: "请选择日期时间",
        },
        readerMode: {
            type: Boolean,
            default: false,
        },
    },
})
export default class DateTime extends ComponentMixins {
    private format: string;
    get type() {
        switch (this.format) {
            case "yyyy":
                return "year";
            case "yyyy-MM":
                return "month";
            case "yyyy-MM-dd":
                return "date";
            case "yyyy-MM-dd HH:mm":
                return "datetime";
            default:
                return "datetime";
        }
    }
}
